<template>
  <div class="new-program pt-10">
    <v-row>
      <v-col sm="8">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Ingredients</h2>
          <v-btn
            class="primary--text mr-5"
            text
            depressed
            :to="{
              name: 'draft.recipe',
            }"
          >
            Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :to="{
              name: 'form.recipe.flavour-boosters',
              params: { id: $attrs.id },
            }"
            depressed
            text
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="8">
        <v-card outlined>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <recipe-ingredients-form
              :isNew="isNew"
              :form-data="form"
              @updateTitle="changeFormTitle"
            />
          </v-card-text>

          <v-divider />

          <v-card-actions class="pa-4">
            <v-btn color="blue darken-1" text @click="create">Clear</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="bg-primary-gradient primary px-12"
              @click="save"
              :loading="form.$busy"
            >
              {{ isNew ? 'Add' : 'Save' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col sm="8">
        <v-card outlined>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Quantity</th>
                  <th class="text-left">Unit</th>
                  <th class="text-left">Swap Group</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="no-data" v-if="ingredients.length === 0 && !loading">
                  <td colspan="4" class="text-center headline grey--text py-12">
                    NO DATA
                  </td>
                </tr>

                <tr v-for="(item, index) in ingredients" :key="index">
                  <td>{{ item.title }}</td>
                  <td>{{ item.food_ingredient.serving_quantity }}</td>
                  <td>{{ item.food_ingredient.serving_unit }}</td>
                  <td>{{ $get(item.food_ingredient.swap, 'name') }}</td>
                  <td width="8%">
                    <v-row no-gutters>
                      <v-col cols="6" class="pr-8">
                        <v-icon @click="editItem(item)">
                          {{ icons.pencil }}
                        </v-icon>
                      </v-col>
                      <v-col>
                        <v-icon @click="deleteItem(item)" color="error">
                          {{ icons.delete }}
                        </v-icon>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDelete"></confirm-dialog>
  </div>
</template>

<script>
import RecipeIngredientsForm from '../components/RecipeIngredientsForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import Form from '@/utils/form'

import { mdiArrowRight, mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { pick, get } from 'lodash'

const emptyValues = {
  ingredient_id: null,
  food_swap_id: null,
  title: null,
  serving_quantity: null,
  serving_unit: null,
  raw: {
    food_id: null,
    title: null,
    serving_quantity: null,
    serving_unit: null,
  },
  food_ingredient: {
    id: null,
  },
  raw_serving_quantity: null,
  raw_serving_unit: null,
  completed: false,
}

export default {
  name: 'RecipeIngredientsTab',

  components: {
    RecipeIngredientsForm,
    ConfirmDialog,
  },

  data() {
    return {
      loading: false,
      icons: {
        next: mdiArrowRight,
        pencil: mdiPencil,
        delete: mdiTrashCanOutline,
      },
      recipeId: this.$attrs.id,
      isNew: true,
      form: new Form(emptyValues),

      dialog: false,
      formTitle: 'Create Ingredient',
      ingredients: [],
    }
  },

  computed: {
    ...mapState({
      selectedData: (state) => state.recipe.selectedData,
    }),
  },

  methods: {
    ...mapActions({
      getSingleData: 'recipe/getSingleData',
      getIngredientData: 'recipe/getIngredientData',
      createIngredientData: 'recipe/createIngredientData',
      updateIngredientData: 'recipe/updateIngredientData',
      deleteIngredientData: 'recipe/deleteIngredientData',
    }),

    async fetchData(id, loadDetails = true) {
      this.loading = true

      if (loadDetails) {
        await this.getSingleData(id)
      }

      await this.getIngredientData(this.selectedData)
      this.ingredients = this.selectedData.ingredients

      this.loading = false
    },

    backToDraft() {
      this.$router.push({ name: 'draft.recipe' })
    },

    nextPage() {
      this.$router.push({ name: 'active.recipe' })
    },

    resetForm() {
      this.form = new Form(emptyValues)
    },

    changeFormTitle(title) {
      this.formTitle = title
    },

    fillForm(data) {
      data = pick(data, [
        'id',
        'title',
        'raw',
        'food_ingredient',
        'completed',
        'methods',
      ])

      data.raw = {
        food_id: get(data.raw, 'id', null),
        title: get(data.raw, 'title', null),
        serving_quantity: get(data.raw, 'serving_quantity', null),
        serving_unit: get(data.raw, 'serving_unit', null),
      }

      data.serving_quantity = data.food_ingredient.serving_quantity
      data.serving_unit = data.food_ingredient.serving_unit

      data.raw_serving_quantity = data.food_ingredient.raw_serving_quantity
      data.raw_serving_unit = data.food_ingredient.raw_serving_unit

      if (data.food_ingredient.swap) {
        data.food_swap_id = data.food_ingredient.swap.id
      }

      this.form = new Form(data)
    },

    create() {
      this.isNew = true
      this.formTitle = 'Create Ingredient'
      this.resetForm()
    },

    editItem(item) {
      this.isNew = false
      this.fillForm(item)

      if (!item.food_ingredient.swap) {
        this.formTitle = item.title
      } else {
        this.formTitle = item.food_ingredient.swap?.name + ': ' + item.title
      }

      this.dialog = true
    },

    close() {
      this.resetForm()
      this.dialog = false
    },

    save() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = this.form.$data()

      payload.foodId = this.$attrs.id
      payload.ingredient_id = this.form.ingredient_id
      payload.food_swap_id = this.form.food_swap_id

      if (this.isNew) {
        this.createIngredientData(payload)
          .then((res) => {
            this.form.$timeout(() => {
              this.form.$busy = false

              // Update current list of ingredients
              this.fetchData(this.$attrs.id, false)
            })
          })
          .catch((err) => {
            this.form.$timeout(() => {
              this.form.$busy = false

              if (err.response && err.response.status === 422) {
                this.form.$setErrors(err.response.data.errors)
              }
            })
          })
      } else {
        this.updateIngredientData(payload)
          .then((res) => {
            this.form.$timeout(() => {
              this.form.$busy = false

              // Update current list of ingredients
              this.fetchData(this.$attrs.id, false)
            })
          })
          .catch((err) => {
            this.form.$timeout(() => {
              this.form.$busy = false

              if (err.response && err.response.status === 422) {
                this.form.$setErrors(err.response.data.errors)
              }
            })
          })
      }
    },

    async deleteItem(item) {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete Ingredient',
        'Are you sure you want to delete?'
      )

      if (confirm) {
        item.foodId = this.$attrs.id
        await this.deleteIngredientData(item)

        // Reload ingredients only
        await this.fetchData(this.$attrs.id, false)
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.$attrs.id) {
        await vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>
